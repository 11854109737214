<template>
    <div>
        <p class="tip">{{ $t('pages.screen.planTip') }}</p>
        <ul>
            <!-- 长期投放 -->
            <li>
                <div>
                    <Icon class="margin-right" type="ios-calendar-outline" />{{ $t('pages.screen.selectDate') }}
                    <span class="type">{{ $t('pages.screen.longTermPlacement') }}</span>
                    <i-switch v-model="switch1" @on-change="change($event, 'date')" />
                </div>
                <Form
                    class="dateFormValidate common"
                    ref="dateFormValidate"
                    :model="dateFormValidate"
                    :rules="dateRuleValidate">
                    <Row v-if="!switch1">
                        <Col span="6">
                            <FormItem prop="start">
                                <DatePicker type="date"  v-model="dateFormValidate.start" :options="optionsStart" key="start" :placeholder="$t('common.input')" :transfer="transfer" @on-change="handleChangeStart"></DatePicker>
                            </FormItem>
                        </Col>
                        <Col span="2" style="text-align: center;">
                            -
                        </Col>
                        <Col span="6">
                            <FormItem prop="end">
                                <DatePicker v-model="dateFormValidate.end" type="date" :options="optionsEnd" :placeholder="$t('common.input')" :transfer="transfer" @on-change="handleChangeEnd"></DatePicker>
                            </FormItem>
                        </Col>
                    </Row>
                    <span v-if="!switch1" class="yearLabel">{{ yearLabel }}</span>
                </Form>
            </li>
            <!-- 每天重复 -->
            <li>
                <div>
                    <Icon class="margin-right"  type="ios-refresh" />{{ $t('pages.screen.weeklySetting') }}
                    <span class="type">{{ $t('pages.screen.dailyRepeat') }}</span>
                    <i-switch v-model="switch2" @on-change="change($event, 'week')" />
                </div>
                <Form
                    class="weekFormValidate common"
                    ref="weekFormValidate"
                    :model="weekFormValidate"
                    :rules="dateRuleValidate"
                >
                    <FormItem v-if="!switch2" prop="weeks">
                        <CheckboxGroup
                            v-model="weekFormValidate.weeks"
                            @on-change="handleWeeksChange"
                        >
                            <Checkbox v-for="(item, index) in weeksList" :label="item.value" :key="index" size="small" border style="margin-bottom: 10px;">{{ item.text }}</Checkbox>
                        </CheckboxGroup>
                    </FormItem>
                </Form>
            </li>
            <!-- 全天播放 -->
            <li>
                <div>
                    <Icon class="margin-right" type="ios-time-outline" />{{ $t('pages.screen.selectTime') }}
                    <span class="type">{{ $t('pages.screen.playAllDay') }}</span>
                    <i-switch v-model="switch3" @on-change="change($event, 'time')" />
                    <Form
                        class="timeFormValidate common"
                        ref="timeFormValidate"
                        :model="timeFormValidate"
                        :rules="timeRuleValidate"
                    >
                        <Row v-if="!switch3">
                            <Col span="6">
                                <FormItem prop="start" label="">
                                    <TimePicker placement="top" format="HH:mm" type="time" v-model="timeFormValidate.start" key="start" :placeholder="$t('common.input')" :transfer="transfer" @on-change="handleChangeTimeStart"></TimePicker>
                                </FormItem>
                            </Col>
                            <Col span="2" style="text-align: center;">-</Col>
                            <Col span="6">
                                <FormItem prop="end">
                                    <TimePicker placement="top" format="HH:mm" v-model="timeFormValidate.end" type="time" :placeholder="$t('common.input')" :transfer="transfer" @on-change="handleChangeTimeEnd"></TimePicker>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            editPlan: {
                default: () => {
                    return {}
                }
            },
            transfer: {
                default: false
            }
        },
        data () {
            return {
                dateFormValidate: {
                    start: '',
                    end: ''
                },
                optionsStart: {
                    disabledDate (date) {
                        let t = new Date(),
                            y = t.getFullYear(),
                            m = t.getMonth() + 1,
                            d = t.getDate(),
                            y2 = date.getFullYear(),
                            m2 = date.getMonth() + 1,
                            d2 = date.getDate(),
                            disable = true;
                        if (y2 === y ) {
                            if (m2 === m && d2 >= d) {
                                disable = false
                            }
                            if (m2 > m) {
                                disable = false
                            }
                        }
                        if (y2 - y === 1) {
                            if (m2 < m) disable = false
                            if (m2 === m && d2 < d) disable = false
                        }
                        return disable
                    }
                },
                optionsEnd: {
                    disabledDate (date) {
                        let t = new Date(),
                            y = t.getFullYear(),
                            m = t.getMonth() + 1,
                            d = t.getDate(),
                            y2 = date.getFullYear(),
                            m2 = date.getMonth() + 1,
                            d2 = date.getDate(),
                            disable = true;
                        if (y2 === y ) {
                            if (m2 === m && d2 >= d) {
                                disable = false
                            }
                            if (m2 > m) {
                                disable = false
                            }
                        }
                        if (y2 - y === 1) {
                            if (m2 < m) disable = false
                            if (m2 === m && d2 < d) disable = false
                        }
                        return disable
                    }
                },
                switch1: true,
                switch2: true,
                switch3:  true,
                // weeksModel: [],
                weekFormValidate: {
                    weeks: []
                },
                timeFormValidate: {
                    start: '',
                    end: ''
                },
                plan: {
                    date: {
                        switch: true,
                        start: '',
                        end: ''
                    },
                    week: {
                        switch: true,
                        weeks: []
                    },
                    time: {
                        switch: true,
                        start: '',
                        end: ''
                    }
                },
                yearLabel: '',
            }
        },
        computed: {
            timeRuleValidate() {
                return {
                    start: [
                        { required: true, type: 'string', message: this.$t('common.select'), trigger: 'change' }
                    ],
                    end: [
                        { required: true, type: 'string', message: this.$t('common.select'), trigger: 'change' }
                    ]
                }
            },
            dateRuleValidate() {
                return {
                    start: [
                        { required: true, type: 'date', message: this.$t('common.select'), trigger: 'change'}
                    ],
                    end: [
                        { required: true, type: 'date', message: this.$t('common.select'), trigger: 'change'}
                    ],
                    weeks: [
                        { required: true, type: 'array', min: 1, message: this.$t('common.select'), trigger: 'change' }
                    ]
                }
            },
            weeksList() {
                return [
                    {
                        value: 0,
                        text: this.$t('pages.screen.Monday')
                    },{
                        value: 1,
                        text: this.$t('pages.screen.Tuesday')
                    },{
                        value: 2,
                        text: this.$t('pages.screen.Wednesday')
                    },{
                        value: 3,
                        text: this.$t('pages.screen.Thursday')
                    },{
                        value: 4,
                        text: this.$t('pages.screen.Friday')
                    },{
                        value: 5,
                        text: this.$t('pages.screen.Saturday')
                    },{
                        value: 6,
                        text: this.$t('pages.screen.Sunday')
                    }
                ]
            }
        },
        watch: {
            editPlan(val) {
                if (JSON.stringify(val) !== '{}') {
                    this.plan.date = { ...val.date }
                    this.plan.time = { ...val.time }
                    this.plan.week = { ...val.week }
                    delete this.plan.week.text
                    let reg = /(\d+)-(\d+)$/,
                        start = val.date.start.match(reg),
                        end  = val.date.end.match(reg),
                        t = new Date(),
                        y = t.getFullYear(),
                        _this = this;
                    if (start) {
                        if (this.isLeapYear() && start[0] === '02-29') {//处理闰年
                            this.dateFormValidate.start = new Date(`${y}-02-28`)
                        } else {
                            this.dateFormValidate.start = new Date(`${y}-${start[1]}-${start[2]} 00:00:00`)
                        }
                    } else {
                        this.dateFormValidate.start = ''
                    }
                    if (end) {
                        if (Number(start[1]) > Number(end[1]) || (Number(start[1]) === Number(end[1]) && Number(start[2]) > Number(end[2]))) {
                            y += 1
                        }
                        if (this.isLeapYear() && end[0] === '02-29') {
                            this.dateFormValidate.end = new Date(`${y}-02-28`)
                        } else {
                            this.dateFormValidate.end = new Date(`${y}-${end[1]}-${end[2]} 00:000:00`)
                        }
                    } else {
                        this.dateFormValidate.end = ''
                    }
                    if (start && end) {
                        this.optionsStart = {
                            disabledDate (date) {
                                let t = new Date(),
                                    y = t.getFullYear(),
                                    m = t.getMonth() + 1,
                                    d = t.getDate(),
                                    y2 = date.getFullYear(),
                                    m2 = date.getMonth() + 1,
                                    d2 = date.getDate(),
                                    disable = true;
                                if (y2 === y ) {
                                    if (m2 === m && d2 >= d) {
                                        disable = false
                                    }
                                    if (m2 > m) {
                                        disable = false
                                    }
                                }
                                if (y2 - y === 1) {
                                    if (m2 < m) disable = false
                                    if (m2 === m && d2 < d) disable = false
                                }
                                if (date.getTime() > new Date(_this.dateFormValidate.end).getTime()) {
                                    disable = true
                                }
                                return disable
                            }
                        }
                        this.optionsEnd =  {
                            disabledDate (date) {
                                let t = new Date(),
                                    y = t.getFullYear(),
                                    m = t.getMonth() + 1,
                                    d = t.getDate(),
                                    y2 = date.getFullYear(),
                                    m2 = date.getMonth() + 1,
                                    d2 = date.getDate(),
                                    disable = true;
                                if (y2 === y ) {
                                    if (m2 === m && d2 >= d) {
                                        disable = false
                                    }
                                    if (m2 > m) {
                                        disable = false
                                    }
                                }
                                if (y2 - y === 1) {
                                    if (m2 < m) disable = false
                                    if (m2 === m && d2 < d) {
                                        disable = false
                                    }
                                }

                                if (date.getTime() < new Date(_this.dateFormValidate.start).getTime()) {
                                    disable = true
                                }
                                return disable
                            }
                        }
                    }

                    this.switch1 = val.date.switch
                    this.weekFormValidate.weeks = val.week.weeks
                    this.switch2 = val.week.switch
                    this.timeFormValidate.start = val.time.start
                    this.timeFormValidate.end = val.time.end
                    this.switch3 = val.time.switch

                }
            }
        },
        methods: {
            cancel() {
                this.switch1 = true
                this.switch2 = true
                this.switch3 = true
                this.dateFormValidate = {
                    start: '',
                    end: ''
                }
                this.timeFormValidate = {
                    start: '',
                    end: ''
                }
                this.plan = {
                    date: {
                        switch: true,
                        start: '',
                        end: ''
                    },
                    week: {
                        switch: true,
                        weeks: []
                    },
                    time: {
                        switch: true,
                        start: '',
                        end: ''
                    }
                }
                this.yearLabel = ''
                this.optionsStart = {
                    disabledDate (date) {
                        let t = new Date(),
                            y = t.getFullYear(),
                            m = t.getMonth() + 1,
                            d = t.getDate(),
                            y2 = date.getFullYear(),
                            m2 = date.getMonth() + 1,
                            d2 = date.getDate(),
                            disable = true;
                        if (y2 === y ) {
                            if (m2 === m && d2 >= d) {
                                disable = false
                            }
                            if (m2 > m) {
                                disable = false
                            }
                        }
                        if (y2 - y === 1) {
                            if (m2 < m) disable = false
                            if (m2 === m && d2 < d) disable = false
                        }
                        return disable
                    }
                }
                this.optionsEnd = {
                    disabledDate (date) {
                        let t = new Date(),
                            y = t.getFullYear(),
                            m = t.getMonth() + 1,
                            d = t.getDate(),
                            y2 = date.getFullYear(),
                            m2 = date.getMonth() + 1,
                            d2 = date.getDate(),
                            disable = true;
                        if (y2 === y ) {
                            if (m2 === m && d2 >= d) {
                                disable = false
                            }
                            if (m2 > m) {
                                disable = false
                            }
                        }
                        if (y2 - y === 1) {
                            if (m2 < m) disable = false
                            if (m2 === m && d2 < d) disable = false
                        }
                        return disable
                    }
                }
                this.handleReset()
            },
            isLeapYear() {
                let t = new Date(),
                    y = t.getFullYear();
                return y % 400 == 0 || (y % 4 == 0 && y % 100 != 0)
            },
            handleChangeStart(e) {
                if (e) {
                    let result =  e.match(/^(\d+)-(\d+)-(\d+)$/);
                    this.plan.date.start = `${result[2]}-${result[3]}`
                    this.optionsEnd = {
                        disabledDate (date) {
                            let t = new Date(),
                                y = t.getFullYear(),
                                m = t.getMonth() + 1,
                                d = t.getDate(),
                                y2 = date.getFullYear(),
                                m2 = date.getMonth() + 1,
                                d2 = date.getDate(),
                                disable = true;
                            if (y2 === y ) {
                                if (m2 === m && d2 >= d) {
                                    disable = false
                                }
                                if (m2 > m) {
                                    disable = false
                                }
                            }
                            if (y2 - y === 1) {
                                if (m2 < m) disable = false
                                if (m2 === m && d2 < d) {
                                    disable = false
                                }
                            }
                            if (date.getTime() < new Date(`${e} 00:00:00`).getTime()) {
                                disable = true
                            }
                            return disable
                        }
                    }
                } else {
                    this.plan.date.start = ''
                }
                this.$emit('on-change', this.plan)
            },
            handleChangeEnd(e) {
                if (e) {
                    let t = new Date(),
                        m = t.getMonth() + 1,
                        d = t.getDate(),
                        result =  e.match(/^(\d+)-(\d+)-(\d+)$/);
                    this.plan.date.end = `${result[2]}-${result[3]}`
                    this.optionsStart = {
                        disabledDate (date) {
                            let t = new Date(),
                                y = t.getFullYear(),
                                m = t.getMonth() + 1,
                                d = t.getDate(),
                                y2 = date.getFullYear(),
                                m2 = date.getMonth() + 1,
                                d2 = date.getDate(),
                                disable = true;
                            if (y2 === y ) {
                                if (m2 === m && d2 >= d) {
                                    disable = false
                                }
                                if (m2 > m) {
                                    disable = false
                                }
                            }
                            if (y2 - y === 1) {
                                if (m2 < m) disable = false
                                if (m2 === m && d2 < d) disable = false
                            }
                            if (date.getTime() > new Date(`${e} 00:00:00`).getTime()) {
                                disable = true
                            }
                            return disable
                        }
                    }
                    if (m > Number(result[2]) || (m === Number(result[2]) && d > Number(result[3]))) {
                        this.yearLabel = this.$t('pages.screen.nextYear')
                    } else {
                        this.yearLabel = ''
                    }
                } else {
                    this.plan.date.end = ''
                }
            },
            change(e, name) {
                this.plan[name].switch = e
            },
            handleWeeksChange(e) {
                this.plan.week.weeks = e
            },
            handleChangeTimeStart(e) {
                this.plan.time.start = e
            },
            handleChangeTimeEnd(e) {
                this.plan.time.end = e
            },
            handleSubmit () {
                let dateValid = false,
                    weekValid = false,
                    timeValid = false;

                this.$refs['dateFormValidate'].validate((valid) => {
                    dateValid = valid
                })
                this.$refs['weekFormValidate'].validate((valid) => {
                    weekValid = valid
                })
                this.$refs['timeFormValidate'].validate((valid) => {
                    timeValid = valid
                })
                if (dateValid && weekValid && timeValid) {
                    this.$emit('on-change', this.plan)
                    return true
                }
                return false
            },
            handleReset () {
                this.$refs['dateFormValidate'].resetFields();
                this.$refs['weekFormValidate'].resetFields();
                this.$refs['timeFormValidate'].resetFields();
            },
            // initTime() {
            //     let t = new Date(),
            //         h = String(t.getHours()).padStart("2", "0"),
            //         mi = String(t.getMinutes()).padStart("2", "0"),
            //         start = `${h}:${mi}`;
            //         this.timeFormValidate.start = start
            //         this.plan.time.start = start
            //         this.$emit('on-change', this.plan)
            // }
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
.tip {
    margin-bottom: 40px;
    font-size: 12px;
}
.weekFormValidate {
    margin-bottom: 30px;
}
.common {
    position: relative;
    height: 96px;
    padding-top: 20px;
    .yearLabel {
        position: absolute;
        bottom: 15px;
        left: 0;
        padding: 0 8px;
        color: #fff;
        background: @primary-color;
        border-radius: 20px;
        font-size: 10px;
    }
}
.type {
    margin: 0 10px 0 77px;
}
</style>