<template>
    <Modal
        class="modal"
        v-model="modal"
        title=""
        width="640"
        :mask-closable="false"
        :closable="false"
        :transfer="false">
        <Icon class="closeIcon" size="31" type="ios-close" @click="handleCancel" />
        <div class="content">{{ $t('pages.space.postTip') }}</div>
        <div slot="footer" class="modal-footer">
            <Button type="text" @click="cancel">{{ $t('pages.space.cancel') }}</Button>
            <Button type="primary" @click="close">{{ $t('pages.space.confirmSelect') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            cancel: Function,
            confirm: Function,
            close: Function
        },
        data () {
            return {
                modal: false,
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
            },
        },
        methods: {
            handleCancel() {
                this.cancel()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #999;
    &:hover {
        color: #444;
    }
}
.content {
    padding: 70px 0 50px;
    text-align: center;
    font-size: 18px;
    color: #afb4ba;
}
</style>