<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.label.editLabel')"
        :mask-closable="false"
        :transfer="false">
        <div class="left-bar">
            <Input v-model.trim="query" clearable prefix="ios-search" :placeholder="$t('pages.label.addOrSearch')" @on-change="handleSearch" />
            <Tooltip :content="query" theme="light" v-if="showAdd && query.length <= 32" :delay="1000">
                <div class="add line-overflow pointer" @click="handleAdd">{{ $t('pages.label.create') }}"{{ query }}"</div>
            </Tooltip>
            <load-more :onRefresh="handleReachBottom" :disabled="isLoaded" height="200px">
                <CheckboxGroup v-model="value" @on-change="checkAllGroupChange">
                    <Tooltip class="tooltip" :content="item.name" theme="light" placement="right" transfer v-for="item in list" :key="item.rowid" :delay="1000">
                        <Checkbox class="checkbox" :label="item.rowid" @click.native="handleCheckbox(item.rowid)">{{ item.name }}</Checkbox>
                    </Tooltip>
                </CheckboxGroup>
            </load-more>
        </div>
        <div class="right-bar">
            <div v-if="selectedList.length !== 0">{{ selectedList.length }}{{ $t('pages.label.unit') }}{{ selectedList.length>1?$t('pages.label.labels'):$t('pages.label.label') }}</div>
            <Tooltip class="tooltip" :content="item.name" theme="light" transfer v-for="item in selectedList" :key="item.label_id" :delay="1000">
                <Button class="label" type="default" shape="circle" @click="handleRemove(item.label_id)">
                    {{ item.name }}
                    <Icon class="close" type="ios-close-circle" />
                </Button>
            </Tooltip>
        </div>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    import loadMore from '@/views/components/loadMore'

    export default {
        name: "",
        mixins: [],
        components: {
            loadMore
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: null
            },
            material_ids: {
                default: () => {
                    return []
                }
            },
            label_type: {
                default: 'material'
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                list: [],
                value: [],
                selectedList: [],
                current: 1,
                pageSize: 10,
                total: 0,
                query: '',
                showAdd: false,
                itemRowid: null,
                searching: false,
                isLoaded: false
            }
        },
        computed: {},
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    await this.getData()
                    if (this.material_ids.length >= 1) {
                        let params = {
                                rowid_list: JSON.stringify(this.material_ids)
                            },
                            url = 'materials/batch/labels'
                        if (this.label_type === 'terminal') {
                            url = 'terminals/batch_labels'
                        }
                        this.$api.get(url, { params }).then(({ data }) => {
                            this.selectedList = data
                            this.value = data.map((item) => {
                                return item.label_id
                            })
                        })
                    }
                }
            },
            value: {
                handler(val) {
                    let len = val.length,
                        len2 = this.list.length,
                        len3 = this.selectedList.length,
                        result = [];
                    for (let i = 0; i < len; i++) {
                        let existed = false
                        for (let k = 0; k < len3; k++) {
                            if (val[i] === this.selectedList[k].label_id) {
                                existed = true
                                break;
                            }
                        }
                        if (!existed) {
                            for (let j = 0; j < len2; j++) {
                                if (val[i] === this.list[j].rowid)  {
                                    result.push({
                                        label_id: this.list[j].rowid,
                                        name: this.list[j].name
                                    })
                                    break;
                                }
                            }
                        }
                    }
                    this.selectedList.push(...result)
                },
                deep: true
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.query = ''
                this.value = []
                this.list = []
                this.current = 1
                this.selectedList = []
                this.$emit('cancel')
            },
            submit() {
                let url = 'materials/batch/labels'
                if (this.label_type === 'terminal') {
                    url = 'terminals/batch_labels'
                }
                this.$api.put(url, { label_id_list: this.value, rowid_list: this.material_ids, label_type: this.label_type }).then(() => {
                    this.$emit('confirm')
                    this.cancel()
                    this.$Message.success(this.$t('message.success'))
                })
            },
            handleSearch() {
                if (this.searching) return
                if (!this.query) {
                    this.showAdd = false
                    this.handleClear()
                    return
                }
                this.current = 1
                this.list = []
                this.getData().then((data) => {
                    if (this.query) {
                        if (data.length === 0) {
                            this.showAdd = true
                        } else {
                            let show = true,
                                len = data.length;
                            for (let i = 0; i < len; i++) {
                                if (data[i].name === this.query) {
                                    show = false
                                    break;
                                }
                            }
                            if (show) {
                                this.showAdd = true
                            } else {
                                this.showAdd = false
                            }
                        }
                    }
                })
            },
            handleAdd() {
                this.current = 1
                this.list = []
                let params = {
                        name: this.query,
                        bucket_token: this.bucket_token,
                        label_type: this.label_type
                    }
                this.$api.post('labels', params).then(({ rowid }) => {
                    this.showAdd = false
                    this.query = ''
                    this.getData().then(() => {
                        this.value.push(rowid)
                    })
                    this.$Message.success(this.$t('message.success'));
                    this.$emit('refresh')
                })
            },
            getData() {
                let params = {
                    query: this.query,
                    bucket_token: this.bucket_token,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    label_type: this.label_type
                }
                this.searching = true
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.current += 1
                        this.list.push(...data)
                        this.total = total_count
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                        return data
                    }
                    return []
                }).finally(() => {
                    this.searching = false
                })
            },
            handleRemove(label_id) {
                let index = this.value.indexOf(label_id),
                    len = this.selectedList.length;
                if (index !== -1) {
                    this.value.splice(index, 1)
                }
                for (let i =0; i < len; i++) {
                    if (label_id == this.selectedList[i].label_id) {
                        this.selectedList.splice(i, 1)
                        break;
                    }
                }
            },
            async handleReachBottom(done) {
                await this.getData()
                done()
            },
            checkAllGroupChange(value) {
                if (value.indexOf(this.itemRowid) === -1) {
                    let len = this.selectedList.length,
                        index = null;
                    for(let i = 0; i < len; i++) {
                        if (this.itemRowid === this.selectedList[i].label_id) {
                            index = i
                            break;
                        }
                    }
                    this.selectedList.splice(index, 1)
                }

            },
            handleCheckbox(rowid) {
                this.itemRowid = rowid
            },
            handleClear() {
                this.current = 1
                this.list = []
                this.getData()
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
@deep: ~">>>";
.left-bar {
    display: inline-block;
    margin-right: 20px;
    padding-right: 50px;
    border-right: 2px solid #f2f2f2;
    width: 280px;
    .tooltip {
        display: block;
        .checkbox {
            overflow: hidden;
            display: block;
            max-width: 200px;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
    .add {
        margin-top: 10px;
        max-width: 200px;
        padding: 0 15px;
        height: 32px;
        background: #ececec;
        border: 1px solid #cacaca;
        border-radius: 15px;
        line-height: 32px;
        text-align: center;
        &:hover {
            color: @primary-color;
        }
    }
    .tooltip {
        display: block;
        .checkbox {
            overflow: hidden;
            display: block;
            max-width: 200px;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
}
.right-bar {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    .tooltip {
        .label {
            position: relative;
            margin: 10px 10px 0 0;
            .close {
                display: none;
                position: absolute;
                top: -5px;
                right: -5px;
            }
            &:hover {
                .close {
                    display: block;
                }
            }
        }
    }
}
.label @{deep} span {
    overflow: hidden;
    max-width: 50px;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>